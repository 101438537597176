<template>
  <div>
    <ManageInformation />
  </div>
</template>
<script>
import ManageInformation from "@/components/ManageInformation/ManageInformation";
export default {
  components: {
    ManageInformation,
  },
  created() {},
};
</script>
