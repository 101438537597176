<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row>
      <v-col cols="6" md="6" sm="6">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 700;
            font-size: 31px;
            line-height: 51px;
          "
        >
          ข้อมูลคณะ
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6" md="6" sm="6" class="text-right">
        <v-btn
          @click="submit('q_1_1')"
          color="#2AB3A3"
          dark
          style="padding: 12px 16px; gap: 8px; width: 158px; height: 48px"
          >บันทึก</v-btn
        >
      </v-col>
    </v-row>
    <v-divider style="border: 1px solid #d0d5dd" class="mt-4"></v-divider>
    <v-row>
      <v-col cols="12" md="12" sm="12" xs="12" class="mt-10">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          สารจากคณบดี
        </span>
      </v-col>
    </v-row>
    <v-card outlined class="pa-6 mt-4">
      <v-row>
        <v-col cols="12" md="6" sm="6"
          ><span class="pt-2">ชื่อหัวข้อ (TH)</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            v-model="form.title_th"
            placeholder="ระบุชื่อหัวข้อ (TH)"
            dense
            outlined
            :rules="rules.required"
            ref="textField"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"
          ><span class="pt-2">ชื่อหัวข้อ (EN)</span>
          <v-text-field
            hide-details
            v-model="form.title_en"
            placeholder="ระบุชื่อหัวข้อ (EN)"
            dense
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" sm="6"
          ><span class="pt-2">คำอธิบายสารจากคณบดี (TH)</span>
          <span style="color: red">*</span>
          <v-textarea
            v-model="form.desc_th"
            placeholder="ระบุคำอธิบายสารจากคณบดี (TH)"
            dense
            outlined
            :rules="rules.required"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6" sm="6"
          ><span class="pt-2">คำอธิบายสารจากคณบดี (EN)</span>
          <v-textarea
            v-model="form.desc_en"
            placeholder="ระบุคำอธิบายสารจากคณบดี (EN)"
            dense
            outlined
          ></v-textarea>
        </v-col>

        <v-col cols="12" md="6" sm="6"
          ><span class="pt-2">ชื่ออาจารย์ (TH)</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            v-model="form.teacher_th"
            placeholder="ระบุชื่ออาจารย์ (TH)"
            dense
            outlined
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"
          ><span class="pt-2">ชื่ออาจารย์ (EN)</span>
          <v-text-field
            hide-details
            v-model="form.teacher_en"
            placeholder="ระบุชื่ออาจารย์ (EN)"
            dense
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" sm="6"
          ><span class="pt-2">ตำแหน่ง (TH)</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            v-model="form.position_th"
            placeholder="ระบุตำแหน่ง (TH)"
            dense
            outlined
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"
          ><span class="pt-2">ตำแหน่ง (EN)</span>
          <v-text-field
            hide-details
            v-model="form.position_en"
            placeholder="ระบุตำแหน่ง (EN)"
            dense
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="12" sm="12"
          ><span class="pt-2">รูปอาจารย์</span>
          <span style="color: red">*</span>
        </v-col>
        <v-row class="ml-0">
          <v-col cols="12" md="2" sm="2" xs="2" v-if="showImage">
            <v-img
              :src="showImage"
              max-width="150px"
              max-height="150px"
            ></v-img>
          </v-col>
          <v-col cols="12" md="2" sm="2" xs="2" v-if="!showImage">
            <v-img
              src="../../assets/noFile.png"
              max-width="150px"
              max-height="150px"
            ></v-img>
          </v-col>
          <v-col cols="12" md="9">
            <span>{{ namePic }}</span>
            <br />
            <span>ขนาดรูปที่แนะนำ : 815 x 1000px</span>
            <br />
            <br />
            <br />
            <input
              type="file"
              accept="image/png, image/jpeg"
              id="file"
              ref="q_1_1"
              v-on:change="submitfileMain('q_1_1')"
            />
          </v-col>
        </v-row>
      </v-row>
    </v-card>

    <v-row>
      <v-col cols="12" class="mt-10">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          ภาพรวมคณะ
        </span>
      </v-col>
    </v-row>
    <v-card outlined class="pa-3 mt-4">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-tabs align-with-title v-model="e1">
            <v-col
              cols="2"
              md="2"
              sm="2"
              :style="
                e1 == 0
                  ? 'border-bottom: 3px solid #2ab3a3'
                  : 'border-bottom: 1px solid #2ab3a3'
              "
            >
              <v-tab class="textResponsive1">
                <span><b>ประวัติคณะ</b></span>
              </v-tab>
            </v-col>
            <v-col
              cols="2"
              md="2"
              sm="2"
              :style="
                e1 == 1
                  ? 'border-bottom: 3px solid #2ab3a3'
                  : 'border-bottom: 1px solid #2ab3a3'
              "
            >
              <v-tab class="textResponsive1">
                <span><b>ปรัชญา</b></span>
              </v-tab>
            </v-col>
            <v-col
              cols="2"
              md="2"
              sm="2"
              :style="
                e1 == 2
                  ? 'border-bottom: 3px solid #2ab3a3'
                  : 'border-bottom: 1px solid #2ab3a3'
              "
            >
              <v-tab class="textResponsive1">
                <span><b>ปณิธาน</b></span>
              </v-tab>
            </v-col>
            <v-col
              cols="2"
              md="2"
              sm="2"
              :style="
                e1 == 3
                  ? 'border-bottom: 3px solid #2ab3a3'
                  : 'border-bottom: 1px solid #2ab3a3'
              "
            >
              <v-tab class="textResponsive1">
                <span><b>วิสัยทัศน์</b></span>
              </v-tab>
            </v-col>
            <v-col
              cols="2"
              md="2"
              sm="2"
              :style="
                e1 == 4
                  ? 'border-bottom: 3px solid #2ab3a3'
                  : 'border-bottom: 1px solid #2ab3a3'
              "
            >
              <v-tab class="textResponsive1">
                <span><b>พันธกิจ</b></span>
              </v-tab>
            </v-col>
            <v-col
              cols="2"
              md="2"
              sm="2"
              :style="
                e1 == 5
                  ? 'border-bottom: 3px solid #2ab3a3'
                  : 'border-bottom: 1px solid #2ab3a3'
              "
            >
              <v-tab class="textResponsive1">
                <span><b>ค่านิยมร่วม</b></span>
              </v-tab>
            </v-col>

            <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

            <v-tab-item>
              <v-row class="pa-4">
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายประวัติคณะ (TH)</span>
                  <span style="color: red">*</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.history_th"
                    :content="content"
                    :options="editorOption"
                    @change="onEditorChange($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.history_th"
                    placeholder="ระบุคำอธิบายประวัติคณะ (TH)"
                    dense
                    outlined
                    :rules="rules.required"
                  ></v-textarea> -->
                </v-col>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายประวัติคณะ (EN)</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.history_en"
                    :content="content1"
                    :options="editorOption"
                    @change="onEditorChange1($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.history_en"
                    placeholder="ระบุคำอธิบายประวัติคณะ (EN)"
                    dense
                    outlined
                  ></v-textarea> -->
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row class="pa-4">
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายปรัชญา (TH)</span>
                  <span style="color: red">*</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.philosophy_th"
                    :content="content2"
                    :options="editorOption"
                    @change="onEditorChange2($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.philosophy_th"
                    placeholder="ระบุคำอธิบายปรัชญา (TH)"
                    dense
                    outlined
                    :rules="rules.required"
                  ></v-textarea> -->
                </v-col>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายปรัชญา (EN)</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.philosophy_en"
                    :content="content3"
                    :options="editorOption"
                    @change="onEditorChange3($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.philosophy_en"
                    placeholder="ระบุคำอธิบายปรัชญา (EN)"
                    dense
                    outlined
                  ></v-textarea> -->
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row class="pa-4">
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายปณิธาน (TH)</span>
                  <span style="color: red">*</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.determination_th"
                    :content="content4"
                    :options="editorOption"
                    @change="onEditorChange4($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.determination_th"
                    placeholder="ระบุคำอธิบายปณิธาน (TH)"
                    dense
                    outlined
                    :rules="rules.required"
                  ></v-textarea> -->
                </v-col>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายปณิธาน (EN)</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.determination_en"
                    :content="content5"
                    :options="editorOption"
                    @change="onEditorChange5($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.determination_en"
                    placeholder="ระบุคำอธิบายปณิธาน (EN)"
                    dense
                    outlined
                  ></v-textarea> -->
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row class="pa-4">
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายวิสัยทัศน์ (TH)</span>
                  <span style="color: red">*</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.vision_th"
                    :content="content6"
                    :options="editorOption"
                    @change="onEditorChange6($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.vision_th"
                    placeholder="ระบุคำอธิบายวิสัยทัศน์ (TH)"
                    dense
                    outlined
                    :rules="rules.required"
                  ></v-textarea -->
                  ></v-col
                >
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายวิสัยทัศน์ (EN)</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.vision_en"
                    :content="content7"
                    :options="editorOption"
                    @change="onEditorChange7($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.vision_en"
                    placeholder="ระบุคำอธิบายวิสัยทัศน์ (EN)"
                    dense
                    outlined
                  ></v-textarea> -->
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row class="pa-4">
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายพันธกิจ (TH)</span>
                  <span style="color: red">*</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.mission_th"
                    :content="content8"
                    :options="editorOption"
                    @change="onEditorChange8($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.mission_th"
                    placeholder="ระบุคำอธิบายพันธกิจ (TH)"
                    dense
                    outlined
                    :rules="rules.required"
                  ></v-textarea> -->
                </v-col>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายพันธกิจ (EN)</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.mission_en"
                    :content="content9"
                    :options="editorOption"
                    @change="onEditorChange9($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.mission_en"
                    placeholder="ระบุคำอธิบายพันธกิจ (EN)"
                    dense
                    outlined
                  ></v-textarea> -->
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row class="pa-4">
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายค่านิยมร่วม (TH)</span>
                  <span style="color: red">*</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.sharedValues_th"
                    :content="content10"
                    :options="editorOption"
                    @change="onEditorChange10($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.sharedValues_th"
                    placeholder="ระบุคำอธิบายค่านิยมร่วม (TH)"
                    dense
                    outlined
                    :rules="rules.required"
                  ></v-textarea> -->
                </v-col>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายค่านิยมร่วม (EN)</span>
                  <quill-editor
                    class="quill233"
                    v-model="form.sharedValues_en"
                    :content="content11"
                    :options="editorOption"
                    @change="onEditorChange11($event)"
                  />
                  <!-- <v-textarea
                    hide-details
                    v-model="form.sharedValues_en"
                    placeholder="ระบุคำอธิบายค่านิยมร่วม (EN)"
                    dense
                    outlined
                  ></v-textarea> -->
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <v-col cols="12" class="mt-10">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          สถิติ
        </span>
      </v-col>
    </v-row>
    <v-card outlined class="pa-6 mt-4">
      <v-row>
        <v-col cols="12" md="4" sm="4">
          <span>นักศึกษาที่สำเร็จการศึกษา</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            placeholder="ระบุจำนวนนักศึกษาที่สำเร็จการศึกษา"
            v-model="form.graduated_amount"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <span>นักศึกษาปริญญาโท</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            placeholder="ระบุจำนวนนักศึกษาปริญญาโท"
            v-model="form.master_amount"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <span>นักศึกษาปริญญาเอก</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            placeholder="ระบุจำนวนนักศึกษาปริญญาเอก"
            v-model="form.doctor_amount"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <v-col cols="12" class="mt-10">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          วิดิโอแนะนำ
        </span>
      </v-col>
    </v-row>
    <v-card outlined class="pa-6 mt-4">
      <v-row>
        <v-col cols="12" md="3" sm="3" v-if="showVideo" class="">
          <p
            style="color: #4caf50; cursor: pointer"
            @click="onClickFile(showVideo)"
          >
            ดูไฟล์วีดีโอ
          </p>
        </v-col>
        <v-col cols="12" md="2" sm="2" v-if="showVideo" class="mt-n3">
          <v-btn color="red" icon @click="deleteFilesVideo()">
            <v-icon>mdi-alpha-x-circle-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <input
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            id="file"
            ref="q_1_2"
            v-on:change="submitfileMain('q_1_2')"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-divider class="mt-10"></v-divider>

    <v-row>
      <v-col cols="12" md="12" sm="12" class="text-right mt-4">
        <v-btn
          @click="submit('q_1_1')"
          color="#2AB3A3"
          dark
          style="padding: 12px 16px; gap: 8px; width: 158px; height: 48px"
          >บันทึก</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";

export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      e1: 0,

      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      file: [],
      loading: false,

      form: {
        title_th: "",
        title_en: "",
        desc_th: "",
        desc_en: "",
        teacher_th: "",
        teacher_en: "",
        position_th: "",
        position_en: "",

        history_th: "",
        history_en: "",
        philosophy_th: "",
        philosophy_en: "",
        determination_th: "",
        determination_en: "",
        vision_th: "",
        vision_en: "",
        mission_th: "",
        mission_en: "",
        sharedValues_th: "",
        sharedValues_en: "",

        graduated_amount: "",
        master_amount: "",
        doctor_amount: "",
      },
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },

      items: [],
      itemsComment: [],
      itemsPicture: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
      videoUrl: "",
      showVideo: "",
      nameVideo: "",
      namePic: "",

      content: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",
      content10: "",
      content11: "",

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    document.title = "ข้อมูลคณะ - GSCM Management System";
    this.getAll();
  },
  methods: {
    focusTextField() {
      this.$nextTick(() => {
        this.$refs.textField.focus(); // Use $refs to access the text field element and call the focus method
      });
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    onEditorChange2({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content2 = html;
    },
    onEditorChange3({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content3 = html;
    },
    onEditorChange4({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content4 = html;
    },
    onEditorChange5({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content5 = html;
    },
    onEditorChange6({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content6 = html;
    },
    onEditorChange7({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content7 = html;
    },
    onEditorChange8({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content8 = html;
    },
    onEditorChange9({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content9 = html;
    },
    onEditorChange10({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content10 = html;
    },
    onEditorChange11({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content11 = html;
    },
    deleteFilesVideo() {
      this.videoUrl = "";
      this.showVideo = "";
      this.nameVideo = "";
    },
    onClickFile(val) {
      window.open(val, "_blank");
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },

    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.imgUrl = response.data.data.path;
          this.showImage = response.data.data.path;
          this.namePic = this.$refs[questionNo].files[0].name;
        }
        if (questionNo == "q_1_2") {
          this.videoUrl = response.data.data.path;
          this.showVideo = response.data.data.path;
          this.nameVideo = this.$refs[questionNo].files[0].name;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },

    async submit(q_1_1) {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        ...this.form,
        imgUrl: this.imgUrl,
        videoUrl: this.videoUrl,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/groupInformations/1`,
        data,
        auth
      );
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `บันทึกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    async getAll() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/groupInformations?id=1`
      );
      console.log("getAll", response.data.data);
      this.form = response.data.data[0];
      this.videoUrl = response.data.data[0].videoUrl;
      this.showVideo = response.data.data[0].videoUrl;
      this.imgUrl = response.data.data[0].imgUrl;
      this.showImage = response.data.data[0].imgUrl;

      this.focusTextField();
    },

    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/banners/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
      // this.getAllbanner();
      this.loading = false;
    },
  },
};
</script>




